import React from "react";
import { Link } from "gatsby";


const FeaturedContentCard = ({ href, title, subtitle, children, stretched = false, small = false }) => (
  <Link to={href} className={'featuredContentCard standardShadow' + (stretched ? ' streched' : (small ? ' small' : ''))}>
    {children}
    <div className="content">
      <p className="title">{title}</p>
      {subtitle && !small && <p className="sub hideOnSmall">{subtitle}</p>}
    </div>
  </Link>
);

export default FeaturedContentCard;