import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image';
import scrollTo from 'gatsby-plugin-smoothscroll';

import Seo from "../components/Seo"
import MainContent from "../components/MainContent"
import BlogEntryBox from "../components/BlogEntryBox"
import RichText from "../components/RichText";
import Header from '../components/Header';
import Footer from '../components/Footer';
import FeaturedContentCard from "../components/FeaturedContentCard"
import PageBox from "../components/PageBox"


const Index = ({ location, data }) => {
  const [ showContent, setShowContent ] = useState(false);

  const firstBlog = data.allContentfulBlog.edges[0].node;

  useEffect(() => {
    let timeout;

    if (location.hash) {
      setShowContent(true);

      timeout = setTimeout(() => {
        scrollTo(location.hash);
      }, 100);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [ location.hash ]);

  return (
    <>
      <Seo
        title={data.contentfulPage.metaTitle}
        description={data.contentfulPage.metaDescription}
        canonical={location.pathname}
      />
      <Header />
      <div className="pageWidth mobileCenterColumn">
        <div className="grid c-2">
          <div>
            <FeaturedContentCard
              href="#What-Is-An-eWallet"
              title="What Is An eWallet?"
              subtitle="Find out what is a digital wallet (or e-wallet) and what it can do for you."
            >
            <Img fluid={data.header1.childImageSharp.fluid} alt="What Is An eWallet?" />
            </FeaturedContentCard>
          </div>
          <div>
            <div className="grid c-2">
              <div>
                <FeaturedContentCard
                  href="#Why-Use-An-eWallet"
                  title="Why Use An eWallet?"
                  small
                >
                  <Img fluid={data.header2.childImageSharp.fluid} alt="Why Use An eWallet?" />
                </FeaturedContentCard>
              </div>
              <div>
                <FeaturedContentCard
                  href="#Which-eWallet-Is-Best"
                  title="Which eWallet Is Best"
                  small
                  >
                    <Img fluid={data.header3.childImageSharp.fluid} alt="Which eWallet Is Best" />
                  </FeaturedContentCard>
              </div>
            </div>
            <div>
              <div className="grid">
                <div>
                  <FeaturedContentCard
                    href="#Is-My-eWallet-App-Safe-and-Secure"
                    title="Is My eWallet App Safe &amp; Secure?"
                    subtitle="Learn more about security features widely used by most popular e-wallets."
                    stretched
                  >
                    <Img fluid={data.header4.childImageSharp.fluid} alt="Is My eWallet App Safe &amp; Secure?" />
                  </FeaturedContentCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pageWidth textCenter padBottom">
        <h1>Choose The Best <span className="textColorAccent">eWallet App</span> In 2024</h1>
        <div className="grid c-2">
          <div>
            <p>Whether you want to improve your consumer experience online or just enjoy yourself by playing your favorite casino games without any fuss, <strong>ewallets</strong> must be your default payment processing option.</p>
          </div>
          <div>
            <p>It doesn’t matter where you live &amp; it doesn’t matter why you do it, having an ewallet account in 2024 is virtually mandatory. Just ask the hundreds of millions of customers who use an ewallet application on a day-to-day basis.</p>
          </div>
        </div>
        <div className={showContent ? 'collapsible show' : 'collapsible'}>
          <RichText
            data={data.contentfulPage.content}
            generateTitleIds
          />
        </div>
        <div className="textCenter">
          <button className="button" onClick={() => setShowContent(!showContent)}>{showContent ? 'Hide content' : 'Read more'}</button>
        </div>
      </div>
      <MainContent pathname={location.pathname}>
        <div className="latestNews backgroundSecondary">
          <h2>Latest News</h2>
          <div className="grid c-2">
            <div>
              <BlogEntryBox blog={firstBlog} />
            </div>
            <div>
              {
                data.allContentfulBlog.edges.slice(1).map(({ node }) => {
                  return (
                    <BlogEntryBox key={node.slug} blog={node} small />
                  );
                })
              }
            </div>
          </div>
        </div>
        <div>
          {data.blogCategories.edges.map(({ node: { name } }) => {
            const blogListProp = `${name}Blogs`;

            if (
              !data.hasOwnProperty(blogListProp) ||
              !data[blogListProp].edges ||
              !Array.isArray(data[blogListProp].edges) ||
              data[blogListProp].edges.length === 0
            ) {
              return null;
            }

            return (
              <div key={name}>
                <h2>{name}</h2>
                <div className="grid c-2">
                  {data[blogListProp].edges.map(({ node }) => {
                    return (
                      <div key={node.slug}>
                        <PageBox
                          title={node.title}
                          summary={node.shortSummary.shortSummary}
                          url={`/blog/${node.slug}`}
                          img={node.featuredImage}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </MainContent>
      <Footer />
    </>
  );
};

export default Index

export const pageQuery = graphql`
  query($contentImgMaxWidth: Int!, $imgQuality: Int!, $traceSvgColor: String!) {
    allContentfulBlog(limit: 4, sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          createdAt
          slug
          title
          shortSummary {
            shortSummary
          }
          featuredImage {
            title
            fluid(
              maxWidth: 768,
              background: $traceSvgColor
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
    header1: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "index/what-is-an-ewallet.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 560,
          quality: $imgQuality,
          traceSVG: {
            color: $traceSvgColor
          }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    header2: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "index/why-use-an-ewallet.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 560,
          quality: $imgQuality,
          traceSVG: {
            color: $traceSvgColor
          }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    header3: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "index/which-ewallet-is-best.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 560,
          quality: $imgQuality,
          traceSVG: {
            color: $traceSvgColor
          }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    header4: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "index/is-my-ewallet-app-safe.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 560,
          quality: $imgQuality,
          traceSVG: {
            color: $traceSvgColor
          }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    contentfulPage(url: {eq: "index"}) {
      metaDescription
      metaTitle
      content {
        raw
        references {
          ... on ContentfulAsset {
            cid: contentful_id
            fixed(
              width: $contentImgMaxWidth,
              quality: $imgQuality,
              background: $traceSvgColor
            ) {
              src
            }
            alt: title
            href: description
          }
        }
      }
    }
    blogCategories: allContentfulBlogCategory(sort: {fields: position, order: ASC}) {
      edges {
        node {
          name
        }
      }
    }
    ApplicationBlogs: allContentfulBlog(filter: {category: {name: {eq: "Application"}}}) {
      edges {
        node {
          slug
          title
          shortSummary {
            shortSummary
          }
          featuredImage {
            fluid(
              maxWidth: 560,
              quality: $imgQuality,
              background: $traceSvgColor
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
            title
          }
        }
      }
    }
    CryptocurrencyBlogs: allContentfulBlog(filter: {category: {name: {eq: "Cryptocurrency"}}}) {
      edges {
        node {
          slug
          title
          shortSummary {
            shortSummary
          }
          featuredImage {
            fluid(
              maxWidth: 560,
              quality: $imgQuality,
              background: $traceSvgColor
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
            title
          }
        }
      }
    }
    FintechBlogs: allContentfulBlog(filter: {category: {name: {eq: "Fintech"}}}) {
      edges {
        node {
          slug
          title
          shortSummary {
            shortSummary
          }
          featuredImage {
            fluid(
              maxWidth: 560,
              quality: $imgQuality,
              background: $traceSvgColor
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
            title
          }
        }
      }
    }
    NewsBlogs: allContentfulBlog(filter: {category: {name: {eq: "News"}}}) {
      edges {
        node {
          slug
          title
          shortSummary {
            shortSummary
          }
          featuredImage {
            fluid(
              maxWidth: 560,
              quality: $imgQuality,
              background: $traceSvgColor
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
            title
          }
        }
      }
    }
  }
`;
