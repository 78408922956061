import React from "react";
import { Link } from "gatsby";
import dateFormat from 'dateformat';
import Img from "gatsby-image";

import { AccessTime } from "../images/svg-icons"


const BlogEntryBox = ({ blog, small = false }) => {
  const { createdAt, slug, title, shortSummary, featuredImage } = blog;

  const blogUrl = `/blog/${slug}`;
  const hasImg = featuredImage && featuredImage.fluid;
  
  return (
    <article className={'blogEntryBox' + (small ? ' small' : '')}>
      {
        hasImg &&
        <div className="picture">
          <Link to={blogUrl}><Img fluid={featuredImage.fluid} alt={featuredImage.title} /></Link>
        </div>
      }
      <div className="content">
        <p className="time"><AccessTime />{dateFormat(createdAt, 'dddd, mmmm dS, yyyy, h:MM:ss TT')}</p>
        <h3><Link to={blogUrl}>{title}</Link></h3>
        {shortSummary && shortSummary.shortSummary && <p>{shortSummary.shortSummary}</p>}
      </div>
    </article>
  );
};

export default BlogEntryBox;