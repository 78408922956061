import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import './PageBox.sass';


function PageBox({ title, summary, url, img }) {
  return (
    <div className="pageBox">
      <div className="fullWidthContainer">
        <Img fluid={img.fluid} alt={img.title} />
      </div>
      <div className="content">
        <h3 className="title textCenter">{title}</h3>
        <p>{summary}</p>
      </div>
      <Link className="readMore" to={url}>Read more</Link>
    </div>
  );
}

export default PageBox;